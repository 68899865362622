<template>
  <div class="h-screen flex w-full bg-img" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="../../assets/images/pages/graphic-2.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">{{ $t('login') }}</h4>
                  <p> {{ $t('loginDes') }}</p>
                </div>
                <vs-input
                    v-validate="'required|min:3'"
                    data-vv-validate-on="blur"
                    name="username"
                    icon="icon icon-user"
                    icon-pack="feather"
                    :label-placeholder="this.$t('userName')"
                    v-model="loginData.user_name"
                    class="w-full no-icon-border"/>
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                <vs-input
                    data-vv-validate-on="blur"
                    type="password"
                    name="password"
                    icon="icon icon-lock"
                    icon-pack="feather"
                    :label-placeholder="this.$t('password')"
                    v-model="loginData.password"
                    v-on:keyup.enter="login"
                    class="w-full mt-6 no-icon-border"/>
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                <vs-button class="float-right my-10" @click="login">{{ $t('login') }}
                </vs-button>


              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import config from '../../../config';
import {EventBus} from "@/event-bus";
import qs from "qs";

export default {
  data() {
    return {
      user_name: '',
      user_id: localStorage.getItem('user_id'),
      /*  config: {
        headers: {
          'access-control-allow-origin': '*',
          'content-encoding': 'gzip',
          'content-length': 310,
          'content-type': 'application/json; charset=UTF-8',
          'vary': 'Origin'
        },
      },*/
      loginData: {
        password: '',
        passphrase: '',
        passphrase_id: '',
        user_id: '',
        user_name: '',
        jwt: '',
        merchant_id: '',
        store_id: '',
        store_name: '',
      },

    }
  },


  methods: {

    login() {
      const formData = new FormData();
      formData.append("Username", this.loginData.user_name);
      formData.append("Password", this.loginData.password);
      this.$http.post(`${config.API_BASE}/api/v1/login`, formData, this.config).then((response) => {
        localStorage.setItem('user_name', this.loginData.user_name)

        const data = response.data.Result
        console.log("data", data)
        if (data.Login === "True") {
          if (data.UserType === "1") {

            setTimeout(() => {
              this.$router.push({
                name: 'admin',
                params: {
                  id: data.CariIND,

                }
              })
            }, 200);
          } else if (data.UserType === "2") {

            setTimeout(() => {

              this.$router.push({
                name: 'order',
                params: {
                  id: data.CariIND,

                }
              })
            }, 200);
          }else if (data.UserType === "3") {

            setTimeout(() => {

              this.$router.push({
                name: 'delivery',
              })
            }, 200);
          }
        } else {
          this.$vs.notify({
            color: 'danger',
            title: 'Login Failed',
            text: 'Please try again.'
          })
        }


      });


    },

  }
}
</script>

<style lang="scss">
.modal-dialog {

}

.modal-content {
  height: 400px !important;
}

.errorLabel {
  background: #eab8c299;
  color: #c10028;
  text-align: center;
  border-radius: 2px;
}

.addButton {
  width: 150px;
  background: #c10028;
  color: white;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 0;
  cursor: pointer;
  margin: auto;
}
</style>
